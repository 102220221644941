import { RouterOptions } from 'vue-router'

import { posts, services } from './graphcms/content.json'

const graphcmsRoutes = [
  ...posts.map((post) => {
    return {
      path: `/blog-posts/${post.slug}`,
      name: post.title,
      component: () => import('./views/Article.vue'),
      props: {
        slug: post.slug,
        displayDate: true,
        date: post.date,
        title: post.title,
        excerpt: post.excerpt,
        body: post.content.html,
        imageUrl: post.coverImage?.url,
        imageAlt: `Cover image for '${post.title}'`
      }
    }
  }),
  ...services.map((service) => {
    return {
      path: `/services/${service.slug}`,
      name: service.title,
      component: () => import('./views/Article.vue'),
      props: {
        slug: service.slug,
        displayDate: false,
        date: service.date,
        title: service.title,
        excerpt: service.excerpt,
        body: service.content.html,
        imageUrl: service.coverImage?.url,
        imageAlt: `Cover image for '${service.title}'`
      }
    }
  })
]

const routes: RouterOptions['routes'] = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('./views/About.vue')
  },
  {
    path: '/how-it-works',
    name: 'How it works',
    component: () => import('./views/HowItWorks.vue')
  },
  {
    path: '/for-garages',
    name: 'For garages',
    component: () => import('./views/ForGarages.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('./views/FAQ.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('./views/Contact.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms and conditions',
    component: () => import('./views/TermsAndConditions.vue')
  },
  {
    path: '/garage-network-agreement',
    name: 'Garage network agreement',
    component: () => import('./views/GarageNetworkAgreement.vue')
  },
  {
    path: '/booking',
    name: 'Booking',
    component: () => import('./views/Booking.vue')
  },
  {
    path: '/thank-you',
    name: 'Thank you',
    component: () => import('./views/ThankYou.vue')
  },
  ...graphcmsRoutes,
  {
    path: '/not-found',
    name: 'Not found',
    component: () => import('./views/NotFound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not found',
    component: () => import('./views/NotFound.vue')
  }
]

export const routerOptions = {
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0, behavior: 'auto' }
  }
}
