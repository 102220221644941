const initiateGtm = () => {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith('cookie_storage='))
    ?.split('=')[1]
  console.log('🍪 Tracking cookie value:', cookieValue)
  if (cookieValue === 'granted') {
    console.log('🍪 Initiating GTM')
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', 'GTM-TK73PC5')
  }
}

export default initiateGtm
