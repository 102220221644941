import { defineStore } from 'pinia'

import { Location } from '@/interfaces'
import Bugsnag from '@bugsnag/js'

import { getPostcodeDetails } from '@/utils/postcode-service'

export const useLocationStore = defineStore('location', {
  state: () => ({
    postcode: null as string,
    location: {
      lat: 51.5,
      lng: -0.5
    } as Location,
    radius: 16000 as number // meters
  }),
  actions: {
    async changePostcode(newPostcode: string | null): Promise<void> {
      this.postcode = newPostcode
      Bugsnag.leaveBreadcrumb('User changes a postcode', {
        postcode: newPostcode
      })
      if (this.postcode?.length > 0) {
        try {
          const postcodeDetails: Location = await getPostcodeDetails(
            this.postcode
          )
          if (postcodeDetails) {
            this.changeLocation(postcodeDetails)
          }
        } catch (err) {
          console.log('Failed getting postcode details.')
        }
      }
    },

    changeLocation(newLocation: Location): void {
      this.location = newLocation
      Bugsnag.leaveBreadcrumb('User changes a location', {
        location: newLocation
      })
    },

    changeRadius(newRadius: number): void {
      this.radius = newRadius
      Bugsnag.leaveBreadcrumb('User changes a radius', {
        radius: newRadius
      })
    }
  }
})
