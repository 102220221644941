<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="relative z-40"
      @close="open = false"
      :initialFocus="acceptButtonRef"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-40 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-ribbon-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <!-- <ExclamationIcon
                      class="h-6 w-6 text-blue-ribbon-600"
                      aria-hidden="true"
                    /> -->
                    <svg
                      class="h-6 w-6 text-blue-ribbon-600"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        d="M21.27 10.9c-1.21-.33-2.31-1.46-2.29-2.89c.01-.56-.4-1.02-.96-1.01C15.83 7.03 14 5.22 14 3.02c0-.49-.35-.9-.84-.96C6.53 1.22 2 6.81 2 12c0 5.52 4.48 10 10 10c5.61 0 10.11-4.62 10-10.18a.972.972 0 0 0-.73-.92zM8.5 15c-.83 0-1.5-.67-1.5-1.5S7.67 12 8.5 12s1.5.67 1.5 1.5S9.33 15 8.5 15zm2-5C9.67 10 9 9.33 9 8.5S9.67 7 10.5 7s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5zm4.5 6c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle
                      as="h3"
                      class="text-lg font-medium leading-6 text-gray-900"
                    >
                      This website uses cookies
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        This website uses cookies to analyze site navigation and
                        usage, and to enhance the visitor experience. By
                        choosing "Accept all", you agree to the storing of
                        cookies on your device. You may also choose to proceed
                        with only the cookies necessary for the site to
                        function. You can read our
                        <router-link
                          :to="{ name: 'Terms and conditions' }"
                          class="text-blue-ribbon-500 underline hover:text-blue-ribbon-600"
                          >cookie policy</router-link
                        >
                        to learn more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <button
                  type="button"
                  class="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-ribbon-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-ribbon-700 focus:outline-none focus:ring-2 focus:ring-blue-ribbon-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  @click.prevent="handlePositiveClick"
                  ref="acceptButtonRef"
                >
                  Accept all
                </button>
                <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  @click.prevent="handleNegativeClick"
                  ref="cancelButtonRef"
                >
                  Accept necessary
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useCookie } from 'vue-cookie-next'

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { ExclamationIcon } from '@heroicons/vue/outline'

import initiateGtm from '@/gtm'

const cookie = useCookie()

const open = ref(false)

const acceptButtonRef = ref(null)
const cancelButtonRef = ref(null)

onMounted((): void => {
  checkCookiePolicyOkay()
})

const checkCookiePolicyOkay = (): void => {
  if (!cookie.getCookie('cookie_storage')) {
    open.value = true
  }
}

const handlePositiveClick = (): void => {
  cookie.setCookie('cookie_storage', 'granted', {
    path: '/',
    expire: '30d'
  })
  open.value = false
  initiateGtm()
}

const handleNegativeClick = (): void => {
  cookie.setCookie('cookie_storage', 'denied', {
    path: '/',
    expire: '30d'
  })
  open.value = false
}
</script>
