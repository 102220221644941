import Bugsnag from '@bugsnag/js'
import { Location } from '@/interfaces'

export const getPostcodeDetails = async (
  postcode: string
): Promise<Location> => {
  try {
    const response = await fetch(
      `https://api.postcodes.io/postcodes/${postcode}`
    )
    const { result } = await response.json()
    return {
      lat: result.latitude,
      lng: result.longitude
    }
  } catch (err: any) {
    Bugsnag.notify(err, (event) => {
      event.addMetadata('custom_data', {
        postcode
      })
    })
    console.error(err)
  }
}

export const getPostcodes = async (
  query: string
): Promise<{ result: Record<string, any>[]; status: number }> => {
  try {
    const response = await fetch(
      `https://api.postcodes.io/postcodes?q=${query}`
    )
    const postcodesResult = await response.json()

    return postcodesResult
  } catch (err: any) {
    Bugsnag.notify(err, (event) => {
      event.addMetadata('custom_data', {
        query
      })
    })
    console.error(err)
  }
}
