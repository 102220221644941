import { ViteSSG } from 'vite-ssg'
import { VueCookieNext } from 'vue-cookie-next'
import VueSplide from '@splidejs/vue-splide'
import VCalendar from 'v-calendar'
import { createPinia } from 'pinia'
import VueLazyLoad from 'vue3-lazyload'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import App from './App.vue'
import { routerOptions } from './router'

import './index.css'
import './assets/fonts/fonts.css'
import 'v-calendar/dist/style.css'

import { version, revision } from '../package.json'
import { AppModes } from './enums'

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: AppModes[import.meta.env.MODE],
  enabledReleaseStages: [AppModes.staging, AppModes.production],
  appVersion: `${version}-website-${revision}`
})

const bugsnagVue = Bugsnag.getPlugin('vue')

export const createApp = ViteSSG(
  App,
  routerOptions,
  ({ app, router, routes, isClient, initialState }) => {
    app.use(VueCookieNext)
    app.use(VueSplide)
    app.use(createPinia())
    app.use(VueLazyLoad)
    app.use(bugsnagVue)
    if (isClient) {
      app.use(VCalendar)
    }
  }
)
