<template>
  <Disclosure
    as="nav"
    class="fixed z-20 w-full border-b bg-gray-100 shadow"
    v-slot="{ open }"
  >
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton
            class="inline-flex items-center justify-center rounded-md p-2 text-blue-ribbon-500 hover:bg-gray-200 hover:text-blue-ribbon-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-ribbon"
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div
          class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
        >
          <div class="flex flex-shrink-0 items-center">
            <router-link :to="{ name: 'Home' }">
              <img
                class="block h-8 w-auto sm:hidden"
                width="32"
                height="32"
                src="@/assets/logo-small.svg"
                alt="GaragesNear.me logo"
              />
              <img
                class="hidden h-8 w-auto sm:block"
                width="146"
                height="32"
                src="@/assets/logo.svg"
                alt="GaragesNear.me logo"
              />
            </router-link>
          </div>
          <div class="hidden sm:ml-6 sm:block">
            <div class="flex h-8 gap-4">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="{ name: item.name }"
                class="flex items-center justify-center whitespace-nowrap rounded-md px-3 py-2 text-center text-sm font-medium leading-tight text-blue-ribbon-600 hover:bg-blue-ribbon-100"
                :class="item.name === 'Home' ? 'hidden lg:flex' : ''"
                exact-active-class="bg-blue-ribbon-200 hover:bg-blue-ribbon-300"
                aria-current-value="true"
                >{{ item.name }}</router-link
              >
            </div>
          </div>
        </div>

        <div class="hidden lg:block">
          <a
            rel="noopener"
            target="_blank"
            label="GaragesNear.me dashboard"
            aria-label="GaragesNear.me dashboard"
            href="https://dash.garagesnear.me/"
            class="mr-4 flex h-8 items-center rounded-full bg-blue-ribbon-500 px-4 text-white hover:bg-blue-ribbon-600"
          >
            Garage signin
          </a>
        </div>

        <button
          v-show="showSearch === false"
          @click.prevent="expandSearch"
          class="absolute inset-y-0 right-0 flex items-center sm:static"
        >
          <div
            class="inline-flex rounded-full bg-registration-yellow-500 hover:bg-registration-yellow-600 focus:bg-registration-yellow-600"
            role="group"
          >
            <img
              class="h-8 w-auto rounded-l-full"
              width="32"
              height="32"
              src="@/assets/half-jack.svg"
              alt="Union Jack"
            />
            <div
              class="flex h-8 w-8 items-center justify-center rounded-r-full"
            >
              <SearchIcon class="h-4 w-4" aria-hidden="true" />
            </div>
          </div>
        </button>

        <div
          v-show="showSearch === true"
          class="absolute inset-y-0 right-0 flex items-center rounded-full sm:static"
        >
          <div class="inline-flex rounded-md" role="group">
            <img
              class="h-8 w-auto rounded-l-full"
              width="32"
              height="32"
              src="@/assets/half-jack.svg"
              alt="Union Jack"
            />
            <input
              ref="registrationInput"
              v-model="registration"
              class="font-mandatory h-8 w-0 rounded-none bg-registration-yellow text-center uppercase text-black transition-all placeholder:text-black focus:w-36 focus:px-1 focus:outline-none focus:outline-0"
              :class="registration.trim().length > 0 ? 'w-36' : ''"
              placeholder="Enter your reg"
              @keypress.enter="navigateToBooking"
              @blur="
                () => {
                  if (registration.trim().length < 1) {
                    showSearch = false
                  }
                }
              "
              inputmode="search"
              autocomplete="off"
              spellcheck="false"
            />
            <router-link
              :to="bookingRoute"
              class="flex h-8 w-8 items-center justify-center rounded-r-full bg-registration-yellow hover:bg-registration-yellow-600 focus:bg-registration-yellow-600 focus:outline-none focus:outline-0"
              tag="button"
              @click.prevent="
                () => {
                  registration = ''
                  showSearch = false
                }
              "
              aria-label="Look up your vehicle by registration and select services"
            >
              <ArrowRightIcon
                class="h-4 w-4"
                aria-hidden="true"
                :class="{
                  'horizontal-bounce': animatedRegistrationInputArrow
                }"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pt-2 pb-3">
        <DisclosureButton
          v-for="item in navigation"
          :key="item.name"
          :as="RouterLink"
          :to="{ name: item.name }"
          class="block rounded-md px-3 py-2 text-base font-medium text-blue-ribbon-600 hover:bg-blue-ribbon-100"
          exact-active-class="bg-blue-ribbon-200 hover:bg-blue-ribbon-300"
          aria-current-value
        >
          {{ item.name }}
        </DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import {
  SearchIcon,
  MenuIcon,
  XIcon,
  ArrowRightIcon
} from '@heroicons/vue/outline'
import { computed, ComputedRef, nextTick, ref } from 'vue'
import { RouterLink, RouteLocationRaw, useRouter } from 'vue-router'
import { useLocationStore } from '@/stores/location'

const navigation: { name: string }[] = [
  { name: 'Home' },
  { name: 'About' },
  { name: 'For garages' },
  { name: 'FAQ' },
  { name: 'Contact' }
]

const router = useRouter()

const locationStore = useLocationStore()

const registrationInput = ref(null)
const registration = ref('')
const formattedRegistration: ComputedRef<string> = computed((): string =>
  registration.value.replace(/[^\d\wöÖäÄåÅüÜøØ]/gi, '').toUpperCase()
)
const showSearch = ref(false)

const postcodeValue: ComputedRef<string> = computed((): string => {
  return locationStore.postcode
})

const animatedRegistrationInputArrow = true

const expandSearch = (): void => {
  showSearch.value = true
  nextTick(() => {
    registrationInput.value.focus()
  })
}

const bookingRoute: ComputedRef<RouteLocationRaw> = computed(
  (): RouteLocationRaw => {
    const newRoute: RouteLocationRaw = {
      name: 'Booking',
      query: {
        ...(formattedRegistration.value.length > 0 && {
          registration: formattedRegistration.value
        })
      }
    }
    if (postcodeValue.value) {
      newRoute.query = {
        postcode: postcodeValue.value as unknown as string
      }
    }
    return newRoute
  }
)

const navigateToBooking = (): void => {
  router.push(bookingRoute.value)
  registration.value = ''
  showSearch.value = false
}
</script>
