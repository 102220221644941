export enum AppModes {
  development = 'gnm-development',
  staging = 'gnm-staging',
  production = 'gnm-production'
}

export enum BOOKING_STEPS {
  CHOOSE_VEHICLE,
  CHOOSE_SERVICE,
  SELECT_GARAGE,
  COMPLETE_BOOKING
}
