<template>
  <footer
    v-once
    class="pf bg-blue-ribbon-500 px-8 py-12 text-base text-white lg:py-8 lg:px-6"
  >
    <div class="m-auto mb-12 max-w-7xl lg:mb-16">
      <img
        class="h-12 w-auto"
        width="219"
        height="48"
        src="@/assets/logo-white.svg"
        alt="GaragesNear.me logo"
      />
    </div>
    <div
      class="m-auto grid max-w-7xl gap-6 md:grid-cols-2 lg:grid-cols-4 lg:gap-8"
    >
      <div>
        <h4 class="text-lg font-semibold">Pages</h4>
        <nav class="mt-4">
          <ul class="h">
            <li>
              <router-link to="/" class="text-base font-normal">
                Home
              </router-link>
            </li>
            <li>
              <router-link to="/about" class="text-base font-normal">
                About
              </router-link>
            </li>
            <li>
              <router-link to="/how-it-works" class="text-base font-normal">
                How it works
              </router-link>
            </li>
            <li>
              <router-link to="/for-garages" class="text-base font-normal">
                For garages
              </router-link>
            </li>
            <li>
              <router-link to="/faq" class="text-base font-normal">
                FAQ
              </router-link>
            </li>
            <li>
              <router-link to="/contact" class="text-base font-normal">
                Contact
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div>
        <h4 class="text-lg font-semibold">Legal</h4>
        <nav class="mt-4">
          <ul class="h">
            <li>
              <router-link
                to="/terms-and-conditions"
                class="text-base font-normal"
              >
                Terms and conditions
              </router-link>
            </li>
            <li>
              <router-link
                to="/garage-network-agreement"
                class="text-base font-normal"
              >
                Garage network agreement
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div>
        <h4 class="text-lg font-semibold">Contact us</h4>
        <nav class="mt-4">
          <ul class="h">
            <li>
              <a
                href="mailto:info@garagesnear.me"
                class="text-base font-normal"
              >
                info@garagesnear.me
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div>
        <h4 class="text-lg font-semibold">Follow us</h4>
        <nav class="mt-4 flex gap-4">
          <a
            rel="noopener"
            target="_blank"
            label="Facebook"
            aria-label="Facebook"
            href="https://www.facebook.com/GaragesNearme-305250540146114/"
            class="text-base font-normal"
          >
            <svg
              class="h-8 w-8"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512"
            >
              <path
                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48c27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                fill="currentColor"
              ></path>
            </svg>
          </a>
          <a
            rel="noopener"
            target="_blank"
            label="Twitter"
            aria-label="Twitter"
            href="https://twitter.com/garagesnearme/"
            class="text-base font-normal"
          >
            <svg
              class="h-8 w-8"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              enable-background="new 0 0 512 512"
              xml:space="preserve"
            >
              <path
                d="M492,109.5c-17.4,7.7-36,12.9-55.6,15.3c20-12,35.4-31,42.6-53.6c-18.7,11.1-39.4,19.2-61.5,23.5
	C399.8,75.8,374.6,64,346.8,64c-53.5,0-96.8,43.4-96.8,96.9c0,7.6,0.8,15,2.5,22.1c-80.5-4-151.9-42.6-199.6-101.3
	c-8.3,14.3-13.1,31-13.1,48.7c0,33.6,17.2,63.3,43.2,80.7C67,210.7,52,206.3,39,199c0,0.4,0,0.8,0,1.2c0,47,33.4,86.1,77.7,95
	c-8.1,2.2-16.7,3.4-25.5,3.4c-6.2,0-12.3-0.6-18.2-1.8c12.3,38.5,48.1,66.5,90.5,67.3c-33.1,26-74.9,41.5-120.3,41.5
	c-7.8,0-15.5-0.5-23.1-1.4C62.8,432,113.7,448,168.3,448C346.6,448,444,300.3,444,172.2c0-4.2-0.1-8.4-0.3-12.5
	C462.6,146,479,129,492,109.5z"
                fill="currentColor"
              ></path>
            </svg>
          </a>

          <a
            rel="noopener"
            target="_blank"
            label="Instagram"
            aria-label="Instagram"
            href="https://www.instagram.com/garagesnearme/"
            class="text-base font-normal"
          >
            <svg
              class="h-8 w-8"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 448 512"
            >
              <path
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9S287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7s74.7 33.5 74.7 74.7s-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8c-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8s26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9c-26.2-26.2-58-34.4-93.9-36.2c-37-2.1-147.9-2.1-184.9 0c-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9c1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0c35.9-1.7 67.7-9.9 93.9-36.2c26.2-26.2 34.4-58 36.2-93.9c2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6c-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6c-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6c29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6c11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                fill="currentColor"
              ></path>
            </svg>
          </a>
        </nav>
      </div>
    </div>
    <div
      class="k ob vb-t-2 vd6 m-auto flex max-w-7xl flex-col items-center justify-between pt-8 md:flex-row lg:mt-20"
    >
      <div class="">
        <p>© GaragesNear.me</p>
        <!-- <p>
          Wisdom is easily acquired when hiding under the bed with a saucepan on
          your head.
        </p> -->
      </div>
    </div>
    <!-- <div
      class="flex flex-col mt-8 items-center k lg:mt-12 md:flex-row justify-between max-w-7xl m-auto"
    >
      <nav class="flex flex-wrap n justify-center">
        <a href="#" class="text-sm font-normal mb-2">Privacy</a>
        <a href="#" class="text-sm font-normal mb-2 ml-4"
          >Content Terms Notice</a
        >
        <a href="#" class="text-sm font-normal mb-2 ml-4">Legal</a>
        <a href="#" class="text-sm font-normal mb-2 ml-4">Features</a>
        <a href="#" class="text-sm font-normal mb-2 ml-4">Landing Pages</a>
      </nav>
    </div> -->
  </footer>
</template>
